// MODALS PLURAL STIMULUS CONTROLLER
// This controller is a universal controller initiated on the body element 
// to handle permanent modals that are not server side rendered using turbo frames.
// It handles the opening and closing of modals, as well as the overlay.
// It also handles the escape key press to close modals.
// It also handles the clicking outside of modals to close them.
// It also handles the clearing of forms when modals are closed.


import { Controller } from "@hotwired/stimulus"
// import {enter, leave, toggle} from 'el-transition'

export default class extends Controller {
  static targets = ["overlay"]
  isFirstTime = true;

  connect() {
    // document.querySelectorAll('.modal').forEach(modal => {
    //   // add event listener to close modal when escape key is pressed on inputs
    //   modal.querySelectorAll('input').forEach(input => {
    //     input.addEventListener('keydown', (event) => {
    //       if (event.key === 'Escape') {
    //         // Code to close the modal goes here
    //         this.closeModal(modal.id)
    //       }
    //     });
    //   });
    // });
  }

  /**
   * Opens a modal and handles the overlay, animations, and event listeners
   * @param {Event} event - The triggering event
   */
  open(event) {
    this.preventDefaultBehavior(event);
    this.handleExistingModal();
    
    const modal = document.getElementById(event.currentTarget.dataset.modalName);
    this.showModal(modal);
    this.setupModalInteractions(modal);
  }

  /**
   * Prevents default event behavior and propagation
   */
  preventDefaultBehavior(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * Handles any existing open modal before showing a new one
   */
  handleExistingModal() {
    this.dispatch("closeAll", {})
    console.log("closeAll dispatched")
    const openModal = document.querySelector('.modal.open, .slideover.open');
    
    if (openModal) {
      this.closeModal(openModal, { clearForm: true, closeOverlay: false });
    } else {
      this.showOverlay();
    }
  }

  /**
   * Shows the overlay with a fade-in animation
   */
  showOverlay() {
    const overlay = this.overlayTarget;
    overlay.style.display = "block";
    requestAnimationFrame(() => overlay.style.opacity = 1);
  }

  /**
   * Displays the modal with appropriate animations
   */
  showModal(modal) {
    modal.style.display = "block";
    
    requestAnimationFrame(() => {
      if (modal.classList.contains('slideover')) {
        modal.style.opacity = 1;
        modal.style.transform = 'translateX(0)';
      } else {
        modal.style.opacity = 1;
        modal.style.transform = 'translateX(-50%) scale(1)';
      }
    });

    modal.classList.add('open');
    document.body.style.overflow = 'hidden';
  }

  /**
   * Sets up modal interactions including focus, keyboard events, and click handlers
   */
  setupModalInteractions(modal) {
    this.setupInputHandling(modal);
    this.setupClickHandlers(modal);
  }

  /**
   * Handles input focus and keyboard events within the modal
   */
  setupInputHandling(modal) {
    const inputs = modal.querySelectorAll('input, textarea');
    if (!inputs.length) return;

    const autofocusInput = modal.querySelector('.autofocus-input');
    if (autofocusInput) {
      autofocusInput.focus();
    }

    inputs.forEach(input => {
      input.addEventListener('keydown', event => {
        if (event.key === 'Escape') {
          this.closeModal(modal);
        }
      });
    });
  }

  /**
   * Sets up click handlers for modal interaction
   */
  setupClickHandlers(modal) {
    // Prevent clicks inside modal from closing it
    modal.onclick = event => event.stopPropagation();

    // Close modal when clicking outside
    window.onclick = event => {
      if (event.target !== modal) {
        this.closeModal(modal);
      }
    };
  }

  close(event){
    let modal = event.currentTarget.closest('.modal');
    if (event.currentTarget.dataset.modalName == 'chatModal') {
      modal = document.getElementById('chatModal');
    }
    this.closeModal(modal);
  }

  closeModal(modal, options = {clearForm: true, closeOverlay: true}){
    // console.log(modal.dataset.controller.includes('modal'))
    // if(modal.dataset.controller.includes('modal')){
    //   modal.dispatchEvent(new CustomEvent('closeModal'));
    // }

    let overlay = document.getElementById('modal-overlay');

    modal.querySelectorAll('input').forEach(input => {
      // Use the same named function for removing the event listener
      // Note: Directly using bind here would not work as it creates a new function
      // You need to bind this function in the constructor or use a different approach to maintain context
      input.removeEventListener('keydown', this.handleEscapeKeyDown.bind(this, modal));
    });
    // Start the transition
    if(options.closeOverlay){
      overlay.style.opacity = 0;
    }

    if(modal.classList.contains('slideover')){
        modal.style.opacity = 0;
        modal.style.transform = 'translateX(100%)';
      }else{
        modal.style.opacity = 0;
        modal.style.transform = 'translateX(-50%) scale(0.7)';
      }

    // Function to hide modal and overlay after transition
    const hideElements = () => {
      if(options.closeOverlay){
        overlay.style.display = "none";
      }
      modal.style.display = "none";
      modal.classList.remove('open');
      document.body.style.overflow = 'auto'; // Restore the body overflow

      // Remove the event listener to avoid memory leaks
      modal.removeEventListener('transitionend', hideElements);
    };

    // Listen for the end of the transition to hide the modal and overlay
    modal.addEventListener('transitionend', hideElements, { once: true });

    // if modal has a form, reset it
    let form = modal.querySelector('form');
    if(form && options.clearForm){
      form.reset();
    }
  }

  handleEscapeKeyDown(modal, event) {
    if (event.key === 'Escape') {
      this.closeModal(modal, false);
    }
  }
}
