import { Controller } from '@hotwired/stimulus'
import { computePosition, flip, offset } from '@floating-ui/dom'

export default class extends Controller {
  static targets = ['trigger', 'tooltip']
  static values = {
    placement: { type: String, default: 'top' },
    offset: { type: Object, default: { mainAxis: 8, crossAxis: 8 } },
    delay: { type: Object, default: { open: 800, close: 0 } }
  }

  connect() {
    this.updatePosition()
    this.boundHandleMouseEnter = this.handleMouseEnter.bind(this)
    this.boundHandleMouseLeave = this.handleMouseLeave.bind(this)
    if (window.matchMedia('(pointer: fine)').matches) {
      this.triggerTarget.addEventListener('mouseenter', this.boundHandleMouseEnter)
      this.triggerTarget.addEventListener('mouseleave', this.boundHandleMouseLeave)
    }
    this.tooltipTarget.dataset.state = 'hidden'
  }

  disconnect() {
    this.triggerTarget.removeEventListener('mouseenter', this.boundHandleMouseEnter)
    this.triggerTarget.removeEventListener('mouseleave', this.boundHandleMouseLeave)
  }

  handleMouseEnter(event) {
    if (event.pointerType && event.pointerType !== 'mouse') return

    this.openTimeout = setTimeout(() => {
      if (!this.triggerTarget.matches(':hover')) return
      
      this.updatePosition()
      this.tooltipTarget.dataset.state = 'visible'
    }, this.delayValue.open)
  }

  handleMouseLeave(event) {
    if (event.pointerType && event.pointerType !== 'mouse') return

    if (this.openTimeout) {
      clearTimeout(this.openTimeout)
      this.openTimeout = null
    }

    this.tooltipTarget.dataset.state = 'hidden'
  }

  updatePosition() {
    computePosition(this.triggerTarget, this.tooltipTarget, {
      placement: this.placementValue,
      middleware: [offset(this.offsetValue), flip()]
    }).then(({x, y}) => {
      Object.assign(this.tooltipTarget.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }
}