import { Controller } from "@hotwired/stimulus"
import { useIntersection } from 'stimulus-use'

import * as Bytescale from "@bytescale/sdk";
import AudioMotionAnalyzer from 'audiomotion-analyzer';

export default class extends Controller {
  static targets = ['visualizer', 'timer', 'resetButton', 'cancelButton', 'finishButton', 'permissionButton', 'noPermissionState']


  connect() {
    this.audioChunks = [];
    this.mediaRecorder = null;
    useIntersection(this)

    // check if audio permission is granted
    navigator.permissions.query({ name: 'microphone' }).then(permissionStatus => {
      if (permissionStatus.state === 'granted') {
        this.noPermissionStateTarget.classList.add('hidden');
        this.visualizerTarget.classList.remove('hidden');
      } else {
        this.noPermissionStateTarget.classList.remove('hidden');
        this.visualizerTarget.classList.add('hidden');
      }
    });

    this.uploadManager = new Bytescale.UploadManager({
      apiKey: "public_12a1yz6vdnNV1DQqyBykzEMyUvpq"
    });

    this.analyzer = new AudioMotionAnalyzer(
      this.visualizerTarget,
      {
        mode: 10,                   // Waveform mode
        gradient: 'classic',      // Cool blue tone
        height: 100,                // Compact height
        showScaleY: false,          // Remove Y axis labels
        showScaleX: false,          // Remove X axis labels
        smoothing: 0.5,             // Very smooth transitions
        lineWidth: 2,               // Slightly thicker line
        fillAlpha: 0,             // Subtle fill under the wave
        bgAlpha: 0,                 // Transparent background
        radius: 0,                  // No rounded corners
        reflexRatio: 0,             // No reflection
        channelLayout: 'single',    // Single channel display
        linearAmplitude: true,      // More natural wave movement
        minDecibels: -150,          // Increased sensitivity (default is -85)
        maxDecibels: -30,          // Adjusted range (default is -25)
        overlay: true,
        showBgColor: false
      }
    );
  }

  appear() {
    if (this.analyzer.audioCtx.state === 'suspended') {
      this.analyzer.audioCtx.resume().then(() => {
        this.initializeMicrophone();
      });
    } else {
      this.initializeMicrophone();
    }
  }

  initializeMicrophone() {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        this.micStream = this.analyzer.audioCtx.createMediaStreamSource(stream);
        this.analyzer.connectInput(this.micStream);
        this.analyzer.volume = 0;

        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.ondataavailable = event => {
          this.audioChunks.push(event.data);
        };
        this.mediaRecorder.start();
      })
      .catch(error => console.error("Error accessing microphone:", error));

    this.startTimer();
  }

  startTimer() {
    this.timer = 0;
    this.timerTarget.textContent = "00:00";
    this.timerInterval = setInterval(() => {
      this.timer++;
      this.timerTarget.textContent = this.formatTime(this.timer);
    }, 1000);
    console.log("Timer started");
  }

  formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  resetRecording() {
    this.audioChunks = [];
    this.mediaRecorder.stop();
    this.mediaRecorder.start();
    clearInterval(this.timerInterval);
    this.startTimer();
  }

  disappear() {
    this.analyzer.disconnectInput(this.micStream);
    this.cancelRecording();
  }

  finishRecording() {
    this.mediaRecorder.stop();
    clearInterval(this.timerInterval);
    this.mediaRecorder.onstop = async () => {
      const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' }); // Specify MIME type
      const audioFile = new File([audioBlob], "recording.wav", { type: 'audio/wav' });
      const fileInput = document.querySelector('.voice-input'); // Adjust selector as needed
      
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(audioFile);
      fileInput.files = dataTransfer.files;

      // let fileUrl = await this.handleFileUpload(audioFile);
      // fileInput.value = fileUrl

      // once the file is set, you can submit the form
      const form = fileInput.closest('form');
      form.requestSubmit();

      this.audioChunks = []; // Reset chunks

      this.element.dispatchEvent(new CustomEvent("closeModal"));
    };
  }

  cancelRecording() {
    if(this.mediaRecorder !== null) {
      this.mediaRecorder.stop();
      this.audioChunks = [];
      clearInterval(this.timerInterval);
      this.timerTarget.textContent = "00:00";
    }
  }

  async handleFileUpload(file) {
    try {
      const { fileUrl, filePath } = await this.uploadManager.upload({ data: file });
      return fileUrl
    } catch (e) {
      alert(`Error:\n${e.message}`); // Alert the error message if the upload fails
    }
  }

  requestPermission() {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        this.noPermissionStateTarget.classList.add('hidden');
        this.visualizerTarget.classList.remove('hidden');
      });
  }
}
