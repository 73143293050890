import { Controller } from "@hotwired/stimulus"
import { object, nativeWindow, globalShortcut, webContents, platform } from '@todesktop/client-core'

export default class extends Controller {
  static values = {
    globalCaptureHotkey: String
  }
  
  connect() {
    this.globalCaptureHotkeyValue = 'alt+space'
    this.setupGlobalShortcut()
  }

  disconnect() {
    globalShortcut.unregister(this.globalCaptureHotkeyValue)
  }

  async setupGlobalShortcut() {    
    if (platform.todesktop.isDesktopApp()) {
      const winIdFromToDesktopBuilder = '5wDIosiWq94q07MZFnHKg'
      const mainWindowRef = await object.retrieve({ id: '8us0l94RHzubERahG4NQY' })
      const winRef = await object.retrieve({ id: winIdFromToDesktopBuilder })

      await webContents.loadURL(
        { ref: await nativeWindow.getWebContents({ ref: winRef }) },
        'https://hyperaide.com/desktop/capture'
      )

      globalShortcut.register(this.globalCaptureHotkeyValue, async () => {
        if(await nativeWindow.isVisible({ref: winRef})) {
          await nativeWindow.hide({ref: winRef})
        } else {
          await nativeWindow.show({ref: winRef})
        }
      })

      await object.on('go-to-settings', async () => {
        await webContents.loadURL(
          { ref: await nativeWindow.getWebContents({ ref: mainWindowRef }) },
          'https://hyperaide.com/settings'
        )
      })
    }
  }
}
