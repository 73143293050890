import { Controller } from "@hotwired/stimulus"
import anime from 'animejs/lib/anime.es.js';

export default class extends Controller {

  static targets = ['actions', 'icon'];

  isOpen = false;

  connect() {
    document.addEventListener('click', this.handleOutsideClick.bind(this));
  }

  disconnect() {
    document.removeEventListener('click', this.handleOutsideClick.bind(this));
  }

  handleOutsideClick(event) {
    if (this.isOpen && !this.element.contains(event.target)) {
      this.toggle();
    }
  }

  toggle(event) {
    if (event) {
      event.stopPropagation();
    }

    this.isOpen = !this.isOpen;
    this.iconTarget.style.display = this.isOpen ? 'none' : 'flex';
    this.actionsTarget.style.display = this.isOpen ? 'flex' : 'none';
    
    if (this.isOpen) {
      this.actionsTarget.style.display = 'flex';
    }

    const timeline = anime.timeline({
      easing: 'cubicBezier(.4, 0, .2, 1)',
      duration: 300
    });

    timeline
      .add({
        targets: this.iconTarget,
        opacity: this.isOpen ? [1, 0] : [0, 1],
        scale: this.isOpen ? [1, 0.8] : [0.8, 1],
        duration: 200
      })
      .add({
        targets: this.element,
        borderRadius: this.isOpen ? ['1rem', '0.5rem'] : ['0.5rem', '2rem'],
        padding: this.isOpen ? ['1rem', '0.5rem'] : ['0.5rem', '1rem'],
      }, '-=100')
      .add({
        targets: this.actionsTarget,
        opacity: this.isOpen ? [0, 1] : [1, 0],
        translateY: this.isOpen ? [10, 0] : [0, 10],
        duration: 200,
        complete: () => {
          if (!this.isOpen) {
            this.actionsTarget.style.display = 'none';
          }
        }
      }, '-=200');
  }

}
