import { Controller } from "@hotwired/stimulus";
import { computePosition, flip, offset } from '@floating-ui/dom'

export default class extends Controller {
  static targets = ['trigger', 'dropdown']
  static values = {
    placement: { type: String, default: 'top' },
    offset: { type: Object, default: { mainAxis: 8, crossAxis: 8 } }
  }

  connect(){
    const translateDirection = this.placementValue.includes('bottom') ? 'translateY(-10px)' : 'translateY(10px)'
    Object.assign(this.dropdownTarget.style, {
      opacity: '0',
      transform: translateDirection,
      transition: 'opacity 150ms ease-out, transform 150ms ease-out'
    })

    this.updatePosition()
    this.boundHandleDocumentClick = this.handleDocumentClick.bind(this)
    this.boundHandleEscape = this.handleEscape.bind(this)
    document.addEventListener('click', this.boundHandleDocumentClick)
    document.addEventListener('keydown', this.boundHandleEscape)
  }

  disconnect() {
    document.removeEventListener('click', this.boundHandleDocumentClick)
    document.removeEventListener('keydown', this.boundHandleEscape)
  }

  handleDocumentClick(event) {
    if (!this.element.contains(event.target) && !this.dropdownTarget.classList.contains('hidden')) {
      const translateDirection = this.placementValue.includes('bottom') ? 'translateY(-10px)' : 'translateY(10px)'
      Object.assign(this.dropdownTarget.style, {
        opacity: '0',
        transform: translateDirection
      })
      this.dropdownTarget.addEventListener('transitionend', () => {
        this.dropdownTarget.classList.add('hidden')
      }, { once: true })
    }
  }

  handleEscape(event) {
    if (event.key === 'Escape' && !this.dropdownTarget.classList.contains('hidden')) {
      const translateDirection = this.placementValue.includes('bottom') ? 'translateY(-10px)' : 'translateY(10px)'
      Object.assign(this.dropdownTarget.style, {
        opacity: '0',
        transform: translateDirection
      })
      this.dropdownTarget.addEventListener('transitionend', () => {
        this.dropdownTarget.classList.add('hidden')
      }, { once: true })
    }
  }

  toggle(event) {
    if (this.dropdownTarget.classList.contains('hidden')) {
      this.dropdownTarget.classList.remove('hidden')
      requestAnimationFrame(() => {
        Object.assign(this.dropdownTarget.style, {
          opacity: '1',
          transform: 'translateY(0)'
        })
      })
    } else {
      const translateDirection = this.placementValue.includes('bottom')  ? 'translateY(-10px)' : 'translateY(10px)'
      Object.assign(this.dropdownTarget.style, {
        opacity: '0',
        transform: translateDirection
      })
      this.dropdownTarget.addEventListener('transitionend', () => {
        this.dropdownTarget.classList.add('hidden')
      }, { once: true })
    }
    this.updatePosition()
  }

  updatePosition() {
    computePosition(this.triggerTarget, this.dropdownTarget, {
      placement: this.placementValue,
      middleware: [offset(this.offsetValue), flip()]
    }).then(({x, y}) => {
      Object.assign(this.dropdownTarget.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }
}

